import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const getMetadata = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        siteTitle: title
        siteDesc: description
        siteUrl
        image
        twitter: twitterUsername
      }
    }
  }
`;

const SEO = ({ title, description, lang, keywords }) => {
  const { site } = useStaticQuery(getMetadata);
  const { siteTitle, siteDesc, siteUrl, image, twitter } = site.siteMetadata;
  const fullTitle = `${title} | ${siteTitle}`;
  return (
    <Helmet htmlAttributes={{ lang }} title={fullTitle}>
      <meta name='description' content={description || siteDesc} />
      <meta name='image' content={image} />
      <meta name='keywords' content={keywords.join(`, `)} />
      {/* facebook card */}
      <meta property='og:url' content={siteUrl} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={siteTitle} />
      <meta property='og:description' content={description || siteDesc} />
      <meta property='og:image' content='/twitter_card_background.png' />
      <meta property='og:image:width' content='400' />
      <meta property='og:image:height' content='300' />
      {/* twitter card */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:creator' content={twitter} />
      <meta name='twitter:title' content={siteTitle} />
      <meta name='twitter:description' content={description || siteDesc} />
      <meta name='twitter:image' content='/twitter_card_background.png' />
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  keywords: [],
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
};

export default SEO;
