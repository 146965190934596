import React from 'react';
import styled from 'styled-components';
import IframeResizer from 'iframe-resizer-react';

export const MainWrapper = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 80ch;
  padding: 0 1.2rem;
`;

export const ExternalEmbed = styled(IframeResizer)`
  margin-bottom: -1rem;
  width: 1px;
  min-width: 100%;
`;

export const ClientOnly = ({ children }) => {
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) return null;

  return <div>{children}</div>;
};
