import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components';
import {
  FiTwitter,
  FiFacebook,
  FiInstagram,
  FiMail,
  FiInbox,
} from 'react-icons/fi';

import { Container } from '../lib';

const getImages = graphql`
  query {
    logo: file(relativePath: { eq: "site_logotype_full.png" }) {
      childImageSharp {
        fixed(width: 133, height: 25) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const Footer = ({ className }) => {
  const { logo } = useStaticQuery(getImages);

  return (
    <footer className={className}>
      <Container className='footer-content'>
        <section className='detail'>
          <Link to='/' className='logo'>
            <Image alt='fathering.me' fixed={logo.childImageSharp.fixed} />
          </Link>
          <p>
            We encourage, empower, and equip young fathers of unplanned pregnancies to
            become the best dads they can be.
          </p>
          <h4>
            <a href='https://twitter.com/search?q=%23OneDadAtATime'>
              #OneDadAtATime
            </a>
          </h4>
          <p className='copyright'>
            <Link to='/'>© {new Date().getFullYear()} fathering.me</Link>
          </p>
        </section>
        <section className='navigation'>
          <div className='site-nav'>
            <h1>Navigate to</h1>
            <nav>
              <ul>
                <li>
                  <a href='/#top'>Home</a>
                </li>
                <li>
                  <a href='/#about'>About</a>
                </li>
                <li>
                  <a href='/#get-involved'>Get Involved</a>
                </li>
                <li>
                  <Link to='/donate' className='donate-button'>
                    Donate
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className='social-nav'>
            <h1>Follow Us!</h1>
            <ul>
              <a href='https://www.facebook.com/fathering.me'>
                <li>
                  <FiFacebook className='icon' />
                  Facebook
                </li>
              </a>
              <a href='https://www.instagram.com/fathering.me'>
                <li>
                  <FiInstagram className='icon' />
                  Instagram
                </li>
              </a>
              <a href='https://twitter.com/fatheringdotme'>
                <li>
                  <FiTwitter className='icon' />
                  Twitter
                </li>
              </a>
              <a href='mailto:hello@fathering.me'>
                <li>
                  <FiInbox className='icon' />
                  Email
                </li>
              </a>
              <li className='mailto'>
                <h1>
                  <FiMail className='icon' />
                </h1>
                <p>
                  922 N 3rd St
                  <br />
                  Harrisburg, PA 17102
                </p>
              </li>
            </ul>
          </div>
        </section>
      </Container>
    </footer>
  );
};

const StyledFooter = styled(Footer)`
  color: var(--white);
  background: var(--blue4);
  padding: 2rem 0 4rem;

  .footer-content {
    display: flex;
    flex-direction: column;

    a {
      color: var(--white);
    }
  }
  .detail {
    grid-area: detail;
    padding-right: 1rem;

    p {
      font-size: calc(var(--font-size-base) / 1.5);
      margin-bottom: 1rem;
    }
  }
  .navigation {
    grid-area: navigation;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'social site';
    margin-top: 3rem;

    div {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: var(--font-size-base);
        font-weight: var(--font-weight-bold);
        text-transform: uppercase;
        line-height: 1;
        padding-bottom: 0.5rem;
      }
      ul {
        list-style: none;
        text-transform: capitalize;
        font-size: calc(var(--font-size-base) / 1.5);
      }
      nav ul li {
        &:hover,
        &:active {
          cursor: pointer;
        }
      }

      .icon {
        margin-right: 0.5rem;
        margin-bottom: -0.25rem;
        stroke: var(--white);
        fill: var(--blue3);
        font-size: calc(var(--font-size-base) / 1.25);
      }
      .mailto {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: baseline;
        p {
          line-height: 1.25;
        }
      }
    }
    .site-nav {
      grid-area: site;
    }
    .social-nav {
      grid-area: social;
    }
  }

  @media (min-width: 768px) {
    .footer-content {
      display: grid;
      grid-template-areas: 'detail navigation';
      grid-template-columns: 50% 50%;
    }
    .navigation {
      margin-top: 0;
      grid-template-areas: 'site social';
    }
  }
`;

export default StyledFooter;
