import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa';

import { Container } from '../lib';

const toggleMenu = () => {
  if (typeof document !== `undefined`) {
    const checkbox = document.getElementById('nav-trigger');
    if (checkbox && checkbox.checked === true) {
      checkbox.checked = false;
    }
  }
};

const getImages = graphql`
  query {
    logo: file(relativePath: { eq: "site_branding_full.png" }) {
      childImageSharp {
        fixed(width: 133, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    flat_logo: file(relativePath: { eq: "site_logotype_full.png" }) {
      childImageSharp {
        fixed(width: 133, height: 25) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const Branding = ({ logo, flat_logo }) => (
  <section className='logo'>
    <div className='full'>
      <Image alt='fathering.me' fixed={logo.childImageSharp.fixed} />
    </div>
    <div className='type'>
      <Image alt='fathering.me' fixed={flat_logo.childImageSharp.fixed} />
    </div>
  </section>
);

const Header = ({ path, className }) => {
  const { logo, flat_logo } = useStaticQuery(getImages);

  return (
    <header className={className} id='top'>
      <Container>
        <Link to='/'>
          <Branding logo={logo} flat_logo={flat_logo} />
        </Link>
        <label htmlFor='nav-trigger' for='nav-trigger' className='menu-toggle'>
          <span class='sr-only'>Open main menu</span>
          <FaBars aria-hidden='true' />
        </label>
        <input type='checkbox' id='nav-trigger' name='' />
        <nav id='main-menu' class='main-menu' aria-label='Main menu'>
          <label
            htmlFor='nav-trigger'
            for='nav-trigger'
            className='menu-toggle menu-close'
          >
            <span class='sr-only'>Close main menu</span>
            <FaTimes aria-hidden='true' />
          </label>
          <ul>
            <li>
              <a href='/#' onClick={toggleMenu()}>
                home
              </a>
            </li>
            {/* {(path) => {
              if (path === '/') {
                return (
                  <> */}
            <li>
              <a href='/#about'>about</a>
            </li>
            <li>
              <a href='/#get-involved'>get involved</a>
            </li>
            {/* </>
                );
              }
            }} */}
            <li>
              <Link to='/donate' className='donate-button'>
                donate
              </Link>
            </li>
          </ul>
        </nav>
        <label for='nav-trigger' className='backdrop' aria-hidden='true'>
          {' '}
        </label>
      </Container>
    </header>
  );
};

const StyledHeader = styled(Header)`
  z-index: 900;
  background: var(--blue4);

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  a {
    color: var(--white);

    &:visited,
    &:hover,
    &:active {
      color: var(--white);
    }
  }

  .logo {
    margin-bottom: -0.75rem;

    .full {
      display: none;
    }

    @media (min-width: 500px) {
      .full {
        display: block;
      }
      .type {
        display: none;
      }
    }
  }
  .sr-only {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border: 0;
    clip: rect(0, 0, 0, 0);
  }
  .menu-toggle {
    display: inline-block;
    padding: 1rem 0;
    line-height: 1;
    font-size: var(--font-size-h4);
    color: var(--white);

    &:hover,
    &:active,
    &:focus {
      color: var(--green);
    }
  }
  .backdrop {
    position: fixed;
    display: block;
    left: 100vw;
    top: 0;
    height: 100vh;
    width: 100%;
    transition: all 0.3s ease;
    z-index: -1;
    cursor: default;
  }
  .main-menu {
    position: fixed;
    display: block;
    left: 100vw;
    top: 0;
    height: 100vh;
    transition: all 0.3s cubic-bezier(0.57, 0.12, 0.16, 0.92);
    z-index: 999;
    border-left: 0.5rem solid var(--blue3);
    background: var(--blue4);

    ul {
      list-style: none;
      height: 100vh;
      width: 200px;
      padding: 10vh 0 50vh;
      text-transform: capitalize;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      li {
        width: 100%;
        padding: 0 2rem;
      }
      a {
        display: block;
        border: 2px solid var(--white);
        border-radius: var(--font-size-h2);
        padding: 1rem;
        margin-left: -1rem;
        text-transform: uppercase;
        text-align: center;
      }

      &:first-child {
        border: none;
      }
    }

    .menu-close {
      padding: 1rem;
    }
    .donate-button {
      background-color: var(--red);
      border: 2px solid var(--red);
      font-weight: var(--font-weight-extrabold);
      transition: all 0.3s cubic-bezier(0.57, 0.12, 0.16, 0.92);

      &:hover,
      &:focus {
        background-color: var(--yellow);
        border: 2px solid var(--yellow);
        color: var(--blue4);
      }
    }
  }

  #nav-trigger {
    display: none;
    pointer-events: none;

    &:checked ~ .main-menu {
      transform: translateX(-200px);
      box-shadow: var(--shadow);

      ul {
        z-index: 1000;
      }
      .menu-close {
        z-index: 1001;
      }
    }
    &:checked ~ .backdrop {
      z-index: 998;
      transform: translateX(-100%);
    }
  }

  @media (min-width: 768px) {
    .menu-toggle,
    .main-menu .menu-close {
      display: none;
    }
    .main-menu {
      display: inline-block;
      position: relative;
      left: auto;
      top: auto;
      height: auto;
      border: none;

      ul {
        display: flex;
        flex-direction: row;
        padding: 0;
        background: none;
        box-shadow: none;
        min-height: none;
        height: auto;
        width: auto;

        li {
          width: auto;
          padding: 0;
        }
        a {
          border: none;
          padding: 1rem;
          margin: 0;
          line-height: 1rem;
          text-transform: uppercase;
          text-align: center;
        }
      }

      .donate-button {
        padding: 1rem 2rem;
        margin-left: 1rem;
      }
    }
  }
`;

export default StyledHeader;
