import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400&family=Open+Sans:ital,wght@0,400;0,800;1,400&family=Oswald:wght@400;700&display=swap');
  :root {
    --white: #F9F9F9;
    --blue4: #424D5E;
    --blue3: #4B5B7D;
    --blue2: #6083AB;
    --blue1: #9ED8D7;
    --green: #67B698;
    --yellow: #EDEA63;
    --red: #DB7559;
    --font-size-h1: 5.05rem;
    --font-size-h2: 3.79rem;
    --font-size-h3: 2.84rem;
    --font-size-h4: 2.13rem;
    --font-size-base: 1.6rem;
    --font-size-root-reset: calc(.625rem + 0.25vw); /* base10 font reset plus a scale up based on viewport width */
    --font-weight-regular: 400;
    --font-weight-bold: 700;
    --font-weight-extrabold: 800;
    --font-stack-Oswald: 'Oswald', sans-serif;
    --font-stack-OpenSans: 'Open Sans', sans-serif;
    --font-stack-Caveat: 'Caveat', cursive;
    --shadow: 0 0 1rem #424D5E;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    touch-action: manipulation;
    font-family: var(--font-stack-OpenSans);
    font-size: var(--font-size-root-reset);
    font-weight: var(--font-weight-regular);
    line-height: 1.65;
    
  }
  body {
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: var(--font-size-base);
    color: var(--blue4);
    background-color: var(--white);
  }
  main {
    flex: 1 0 auto;
  }
  a {
    text-decoration: none;
    color: var(--blue4);

    &:visited, &:hover, &:active {
      color: var(--blue3);
    }
  }
  h1 {
    font-family: var(--font-stack-Oswald);
    font-size: var(--font-size-h1);
    font-weight: var(--font-weight-regular);
    &.bold{
      font-weight: var(--font-weight-bold);
    }
    span.bold {
      font-weight: var(--font-weight-bold);
    }
  }
  h2, h3 {
    font-weight: var(--font-weight-regular);
    font-style: italic;
  }
  h2 {
    font-size: var(--font-size-h2);
  }
  h3 {
    font-size: var(--font-size-h3);
  }
  h4 {
    font-family: var(--font-stack-Caveat);
    font-size: var(--font-size-h4);
  }
  p {
    &.bold {
      font-weight: var(--font-weight-extrabold);
    }
    span.bold {
      font-weight: var(--font-weight-extrabold);
    }
  }
  
`;
