import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { GlobalStyle } from '../globalStyle';
import { MainWrapper } from '../lib';
import Header from './Header';
import Footer from './Footer';

const getMetadata = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        siteTitle: title
      }
    }
  }
`;

const Layout = ({ children }) => {
  const {
    site: {
      siteMetadata: { siteTitle },
    },
  } = useStaticQuery(getMetadata);

  return (
    <>
      <GlobalStyle />
      <MainWrapper>
        <Header siteTitle={siteTitle} />
        <main>{children}</main>
        <Footer />
      </MainWrapper>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
